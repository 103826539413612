import React, { useState, useEffect } from 'react';
import '../styles/AnimationsTexts.css';
import challenge from '../images/challenge.png';
import transform from '../images/transform.png';
import conquer from '../images/conquer.png';
import embrace from '../images/embrace.png';
import inspire from '../images/inspire.png';
import become from '../images/become.png';
import your from '../images/your.png';
import better from '../images/better.png';
import self from '../images/self.png';
import whiteLogo from '../images/spotme-white.png';

function AnimatedText({ onAnimationEnd }) {
    const images = [challenge, transform, conquer, embrace, inspire];
    const endingImages = [become, null, your, null, better, null, self, whiteLogo, null];

    const [index, setIndex] = useState(0);
    const [showPause, setShowPause] = useState(false);
    const [showingEnding, setShowingEnding] = useState(false);

    useEffect(() => {
        // If showing ending images, change every 0.5 seconds. Else, change every 1 second.
        const changeInterval = showingEnding ? 600 : 400;

        const interval = setInterval(() => {
            if (showPause) {
                return; // Don't change images during the pause
            }

            if (!showingEnding && index < images.length - 1) {
                setIndex(prev => prev + 1);
            } else if (!showingEnding && index === images.length - 1) {
                setShowPause(true);
                setTimeout(() => {
                    setShowPause(false);
                    setShowingEnding(true);
                    setIndex(0); // Start from the beginning of endingImages
                }, 800); // 1 second pause
            } else if (showingEnding && index < endingImages.length - 1) {
                setIndex(prev => prev + 1);
            } else {
                clearInterval(interval); // End the loop once all images are shown
                onAnimationEnd();
            }
        }, changeInterval);

        return () => clearInterval(interval);
    }, [index, showingEnding, showPause]);

    if (showPause) {
        return null; // Render nothing during the pause
    }

    const currentImage = showingEnding ? endingImages[index] : images[index];
    if (currentImage !== null) {
        return <img src={currentImage} className="animated-image" alt="Animated Text" />;
    }

}

export default AnimatedText;
