import {React} from 'react';
import { Link } from 'react-router-dom';
import footerLogos from '../images/footer-logos.svg';
import { useNavigate } from 'react-router-dom';
import '../styles/footer.css';

function Footer() {
    const navigate = useNavigate();
    return (
        <div className="footer">
            <div className="footerContainer">
                <div className="footerLinksContainer">
                    <div className="footerLinks">
                        <a onClick={() => navigate('/home')}>Soporte</a>
                        <a onClick={() => navigate('/terms-of-use')}>Términos de Uso</a>
                        <Link to="/privacy-policy">Políticas de Privacidad</Link>
                    </div>
                </div>
                <div className='footerText'>
                    <p>by We Build</p>
                </div>
                <div className="footerLogoContainer">
                    <img src={footerLogos} alt="Footer Logo" className="footerLogo" />
                </div>
            </div>
        </div>
    );

}

export default Footer;
