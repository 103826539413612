import react, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------- STYLES -----------------------
import '../styles/select.css';

// ----------------------- IMAGES -----------------------
import trainee from '../images/traineehalf.png';
import coach from '../images/coachhalf.png';
import coachText from '../images/coach.png';
import traineeText from '../images/atleta.png';

// ----------------------- COMPONENTS -----------------------

function Select() {
    const navigate = useNavigate();
    return (
        <section id="wrapper" class="skewed">
            <div class="layer bottom" onClick={() => navigate('/atleta')}>
                <div class="content-wrap">
                    <div class="content-body">
                        <img src={trainee} alt="Trainee" class="trainee-img"></img>
                        <img src={traineeText} className='textImgTrainee'></img>
                    </div>
                </div>
            </div>
            <div class="layer top" onClick={() => navigate('/coach')}>
                <div class="content-wrap">
                    <div class="content-body">
                        <img src={coach} alt="Coach" class="coach-img"></img>
                        <img src={coachText} className='textImgCoach'></img>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Select;
