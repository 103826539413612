import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/navbar.css';

import circularLogo from '../images/spotme-circular.png';

function Navbar({ navbarLogo }) {

    const navigate = useNavigate();

    return (
        <div className={`logoContainer ${navbarLogo ? 'expand' : ''}`}>
            <img src={circularLogo} alt="Navbar Logo" className={`navbarLogo ${navbarLogo ? 'expand' : ''}`} onClick={() => navigate('/home')} />
            <button class="forX" onClick={() => navigate('/coach')}>PARA COACHES</button>
            <button class="forX" onClick={() => navigate('/atleta')}>PARA ATLETAS</button>
            <button class="signUp">REGISTRARSE</button>
        </div>
    );
}

export default Navbar;
