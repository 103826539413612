import React, { useState, useEffect } from 'react';
import '../styles/Titles.css';


// ----------------------- IMAGES -----------------------
import AnimatedText from './AnimatedText';
import blueLogo from '../images/spotme-blue.png';


// ----------------------- COMPONENTS -----------------------
import Navbar from './Navbar';
import Select from './Select';
import Footer from './Footer';

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // ----------------------- ATRIBUTES -----------------------
    const [animateLogo, setAnimateLogo] = useState(true);
    const [navbarLogo, setnavbarLogo] = useState(true);

    return (
        <div>
            {/* ----------------------- NAV BAR -----------------------*/}
            <Navbar navbarLogo={navbarLogo} />

            {/* ----------------------- BLUE LOGO -----------------------*/}
            <div className="container">
                <img src={blueLogo} alt="Blue Logo" className={`blueLogo ${animateLogo ? 'animate' : ''}`} />
            </div>

            {/* ----------------------- TRAINER / TRAINEE -----------------------*/}
            <Select />

            <Footer />

        </div>
    );
}

export default Home;
