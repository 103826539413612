import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


// ----------------------- STYLES -----------------------
import '../styles/Titles.css';


// ----------------------- IMAGES -----------------------
import AnimatedText from './AnimatedText';
import blueLogo from '../images/spotme-blue.png';


// ----------------------- COMPONENTS -----------------------
import Navbar from './Navbar';
import Select from './Select';

function Titles() {

    // ----------------------- ATRIBUTES -----------------------
    const [showLogo, setShowLogo] = useState(false);
    const [animateLogo, setAnimateLogo] = useState(false);
    const [navbarLogo, setnavbarLogo] = useState(false);
    const navigate = useNavigate();

    // ----------------------- ANIMATIONS -----------------------
    const handleAnimationEnd = () => {
        setShowLogo(true);
        setTimeout(() => {
            navigate('/home');
        }, 1000);
    };

    return (
        <div>
            {/* ----------------------- NAV BAR -----------------------*/}
            {navbarLogo && <Navbar navbarLogo={navbarLogo} />}

            {/* ----------------------- BLUE LOGO -----------------------*/}
            <div className="container">
                <AnimatedText onAnimationEnd={handleAnimationEnd} />
            </div>

            {/* ----------------------- TRAINER / TRAINEE -----------------------*/}
            {showLogo && <Select />}

        </div>
    );
}

export default Titles;
