import './App.css';
import Titles from './components/Titles';
import Coach from './components/Coach';
import Atleta from './components/Atleta';
import Home from './components/Home';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

// Importa HashRouter en lugar de BrowserRouter
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Titles />} />
          <Route path="/home" element={<Home />} />
          <Route path="/coach" element={<Coach />} />
          <Route path="/atleta" element={<Atleta />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-of-use" element={<Terms />} />
        </Routes>
      </Router>
  );
}

export default App;
