import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import '../styles/coach.css';

// ----------------------- IMAGES -----------------------
import SpotMeCoachText from '../images/SpotMeCoach-Text.png';
import CoachPeople from '../images/coachPeople.png';
import pushup from '../images/pushup.png';
import toggleGray from '../images/toggleGray.png';
import toggleBlue from '../images/toggleBlue.png';
import phoneCircle from '../images/phoneCircle.png';
import phones from '../images/phones.png';
import allowCoach from '../images/allowCoach.png';

// ----------------------- COMPONENTS -----------------------
import Navbar from './Navbar';

function Coach() {
    const [navbarLogo, setnavbarLogo] = useState(true);
    const [isToggled, setIsToggled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleToggleChange = () => {
        setIsToggled(!isToggled);
    };

    return (
        <div className='bodyCoach'>

            {/* -----------------------  NAV BAR -----------------------*/}
            <Navbar navbarLogo={navbarLogo} />

            {/* -----------------------  LANDING CONTAINER -----------------------*/}
            <div className='containerLanding'>
                <div className="centered-content">
                    <img src={SpotMeCoachText} alt="SpotMeCoachText" className="landingCoach-text"></img>
                </div>
                <img src={CoachPeople} alt="CoachPeople" className='coachPeople-img'></img>
                <p className='landingText'>Una solución integral: una plataforma software como un servicio (SaaS) diseñada para entrenadores, nutricionistas y creadores de contenido fitness</p>
            </div>

            {/* -----------------------  BLUE CONTAINER -----------------------*/}
            <div className='blueContainer'>
                <img src={pushup} alt="pushup" className='pushup-img'></img>
                <div className='slide-img'></div>
                <div className="text-center">
                    <p className='text-bc'>Creemos en el poder del fitness y en la influencia positiva que los coach, nutricionistas e influencers tienen en las vidas de muchos.</p>
                </div>
            </div>

            {/* -----------------------  BLACK CONTAINER -----------------------*/}
            <div className="blackContainer">
                <p className='text-bc'>Por ello, hemos creado una herramienta que te empodera y  te permite llevar tus conocimientos a más personas sin comprometer su esencia.</p>
            </div>

            {/* -----------------------  POTENTIAL CONTAINER -----------------------*/}
            <div className="potentialContainer">
                <p>Desbloquea tu verdadero potencial</p>
            </div>

            {/* -----------------------  TOGGLE CONTAINER -----------------------*/}
            {/* <div className='toggleContainer'>
                <div className='switchContainer'>
                    <label class="switch">
                        <input type="checkbox" onChange={handleToggleChange}></input>
                        <span class="slider"></span>
                    </label> <img src={isToggled ? toggleBlue : toggleGray} alt="toggleText" className='toggleText'></img>
                </div>
            </div>
            <p>Usted</p>
            <p>Cliente</p> */}

            {/* -----------------------  PHONE CONTAINER -----------------------*/}
            <div className="phoneContainer">
                <div className='phoneWhiteContainer'>
                    <img src={phoneCircle} alt="phoneCircle" className='phoneCircle'></img>
                    <p className='phoneText'>
                        <p>Esta herramienta te permite compartir rutinas de ejercicio, planes de alimentación y consejos de manera escalable, llegando a más personas sin comprometer la calidad del servicio.</p>
                        <p>El sistema te libera de cargas administrativas, dandote más tiempo para perfeccionar y promover tus servicios.</p>
                    </p>
                </div>
            </div>

            {/* -----------------------  MISSION CONTAINER -----------------------*/}
            <div className='missionContainer'>
                <p className='missionText'>
                    <p>Nuestra misión es simplificar el proceso, permitiendo que los entrenadores se centren en lo que mejor saben hacer:</p>
                    <p><b>Transformar vidas a través del fitness.</b></p>
                    <p>Creamos la mejor plataforma personalizada de salud, fitness y bienestar. Un negocio en tu bolsillo al que puedes acceder en cualquier momento y lugar desde tu móvil o tu portátil.</p>
                </p>
                <img src={phones} alt="phones" className='phones'></img>
            </div>

            {/* -----------------------  START CONTAINER -----------------------*/}
            <div className="blueCoachContainer">
                <img src={allowCoach} alt="allowCoach"></img>
                {/* <button className='startbtn'>Empezar</button>/z */}
            </div>
            <Footer />
        </div>
    );
}

export default Coach;
