import react, { useState, useEffect } from 'react';
import Footer from './Footer';
import '../styles/atleta.css';

// ----------------------- IMAGES -----------------------

import SpotMeAtletaText from '../images/SpotMeAtleta-Text.png';
import AtletaPeople from '../images/AtletaPeople.png';
import paintTitle from '../images/paintTitle.png';
import meditate from '../images/meditate.png';
import platform from '../images/platform.png';
import platforms from '../images/platforms.png';
// ----------------------- COMPONENTS -----------------------

import Navbar from './Navbar';

function Atleta() {
    const [navbarLogo, setnavbarLogo] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {/* ----------------------- NAV BAR -----------------------*/}
            <Navbar navbarLogo={navbarLogo} />

            {/* ----------------------- LANDING CONTAINER -----------------------*/}
            <div className='containerLandingAtleta'>
                <img src={SpotMeAtletaText} alt="SpotMeAtletaText" className="landingAtletaTxt-Img"></img>
                <img src={AtletaPeople} alt="AtletaPeople" className='AtletaPeople  '></img>
                <p className='landingAtletaTxt'>Spot Me te ofrece una experiencia integral en fitness y nutrición.  Puedes conectarte con tus entrenadores y nutricionistas preferidos, accediendo a rutinas de ejercicio y planes de alimentación personalizados.</p>
            </div>

            {/* -----------------------  PAINT CONTAINER -----------------------*/}
            <div className="paintContainer">
                <img src={paintTitle} alt="paintTitle" className='paintTitle'></img>
            </div>

            {/* ----------------------- MEDITATE CONTAINERR -----------------------*/}
            <div className="meditateContainer">
                <img src={meditate} alt="meditate" className='meditate'></img>
            </div>

            {/* ----------------------- BLUE CONTAINERR -----------------------*/}
            <div className='blueContainerAtleta'>
                <p className='platformText'>La plataforma te ofrece una interfaz amigable e intuitiva donde puedes seguir tu progreso, tanto en aspectos físicos, como en nutrición, permitiendo una experiencia personalizada y adaptada a tus objetivos y necesidades específicas.</p>
                <img src={platform} alt="platform" className='platformImg'></img>
            </div>

            {/* ----------------------- PLATFORMS CONTAINERR -----------------------*/}
            <div className="platformsContainer">
                <p className='platformsText'>Próximamente en</p>
                <img src={platforms} alt="platforms" className='platformsImg'></img>
            </div>
            <Footer />
        </div>
    );
}

export default Atleta;
